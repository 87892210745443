<template>
  <section
    class="cmt-row services2-section cmt-bgcolor-grey mt_80 res-991-mt-0 clearfix">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-10 col-lg-10 col-md-12"> 
          <div class="section-title style2">
            <div class="title-header">
              <h5>{{ marineSurveyData.subtitle }}</h5>
              <h2 class="title">{{ marineSurveyData.title }}</h2>
            </div>
            <div class="title-desc">
              {{ marineSurveyData.short_desc }}
            </div>
          </div> 
        </div> 
      </div> 
    </div>
    <div class="container">
      <div class="row">
        <div class="cmt-box-col-wrapper mt-30 col-lg-4" v-for="item in marineSurveyData.services" :key="item.id">
          <div class="featured-imagebox featured-imagebox-services style1">
            <div class="featured-content featured-content-services">
              <div class="featured-title">
                <h5>{{ item.title }}</h5>
              </div>
              <div class="featured-thumbnail">
                <img
                  class="img-fluid"
                  :src="getItem(item.image)"
                  alt="image"
                />
              </div>
              <div class="featured-bottom">
                <div class="featured-desc services-text">
                  <p>
                    {{ item.desc }}
                  </p>
                </div> 
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '../Data/data.json'
export default {
  data() {
    return {
      marineSurveyData: ""
    };
  },
  created(){
    this.marineSurveyData = data.marinesurvey
  },
  methods: {
    getItem(pic) {
      if(pic) {
        return require("../assets/images/services/marinesurvey/" + pic)
      } else {
        return null
      }
    }
  }
};
</script>
